import React, { useEffect } from "react";
import { Dialog, Modal } from "staysure-component-library";

import quoteDashBoard from "@/hooks/magnolia/fetchQuoteDashBoard";
import FocusLock from "@/utils/FocusedLock";

import { TextButton } from "../../cta-button/cta-buttons";

interface ConfirmationModalData {
  modalTitle?: string | null;
  modalDescription?: string | null;
  ctaButtonLabel?: string;
  backButtonLabel?: string;
  isOpen?: boolean;
  handleConfirmBtnClick?: () => void;
  handleCancelmBtnClick?: () => void;
}

interface ConfirmationModalPlaceholderType {
  modalTitle: string;
  modalDescription: string;
  ctaButtonLabel: string;
  backButtonLabel: string;
}

const initilConfirmationDataObject = {
  modalTitle: "Are you sure?",
  modalDescription:
    "By canceling you will lose all unsaved changes you have made to your policy",
  ctaButtonLabel: "Continue",
  backButtonLabel: "Go back",
};

function ConfirmationsModal(props: Readonly<ConfirmationModalData>) {
  const [confirmationsModalData, setConfirmationModalData] =
    React.useState<ConfirmationModalPlaceholderType>(
      initilConfirmationDataObject
    );
  const { isOpen, handleConfirmBtnClick, handleCancelmBtnClick } = props;

  useEffect(() => {
    quoteDashBoard()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        setConfirmationModalData({
          modalTitle:
            res.results?.[0]?.modalTitle ??
            initilConfirmationDataObject.modalTitle,
          modalDescription:
            res.results?.[0]?.modalDescription ??
            initilConfirmationDataObject.modalDescription,
          ctaButtonLabel:
            res.results?.[0]?.ctaButtonLabel ??
            initilConfirmationDataObject.ctaButtonLabel,
          backButtonLabel:
            res.results?.[0]?.backButtonLabel ??
            initilConfirmationDataObject.backButtonLabel,
        });
      })
      .catch(() => {
        throw new Error("Something went wrong");
      });
  }, []);

  return (
    <FocusLock disabled={!isOpen}>
      <div data-testid="confirmation-modal">
        <Modal
          className="traveller-remove-modal md:p-spacing-xxxl p-spacing-l md:max-w-[416px] 
              max-w-[327px] w-full"
          isOpen={isOpen}
          underlayStyles={{
            zIndex: 9999,
            background: "rgba(26, 26, 26, 0.6)",
          }}
          modalStyles={{
            display: "flex",
            background: "white",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 8,
          }}
        >
          <Dialog className=" relative">
            <h1
              className="text-text-title font-dmserif font-header-font text-2xs-font-size md:text-3xl sm:text-header-font-size-small sm:leading-custom-size-md
                md:leading-custom-size-xl mb-spacing-s"
            >
              {props?.modalTitle || confirmationsModalData?.modalTitle}
            </h1>
            <p className="leading-custom-size-lg text-confirmation-body sm:text-2xs-font-size">
              {props?.modalDescription ||
                confirmationsModalData?.modalDescription}
            </p>
            <div className="flex md:flex-row flex-col mt-spacing-l gap-x-spacing-xs">
              <TextButton
                hierarchy="primary"
                label={
                  props?.ctaButtonLabel || confirmationsModalData.ctaButtonLabel
                }
                onClick={handleConfirmBtnClick}
                className="md:mb-0 mb-2 !h-[48px] md:w-[148px] w-full !text-confirmation-body leading-[18px]"
              />
              <TextButton
                hierarchy="secondary"
                label={
                  props?.backButtonLabel ||
                  confirmationsModalData.backButtonLabel
                }
                onClick={handleCancelmBtnClick}
                isLeftIcon={false}
                className=" !h-[48px] md:w-[148px] w-full leading-[18px] hover:text-confirmation-secondary-hover focus:!border-confirmation-secondary-focus"
              />
            </div>
          </Dialog>
        </Modal>
      </div>
    </FocusLock>
  );
}
export default ConfirmationsModal;
