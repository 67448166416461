import {
  ContactDetails,
  LandingPage,
  MedicalDashboard,
  MedicalLanding,
  MedicalLandingPage,
  MedicalNothingToDeclare,
  MedicalScreen,
  QuestionEight,
  QuestionFive,
  QuestionFour,
  QuestionMedicalConditions,
  QuestionMedicalConfirmation,
  QuestionMedicalTreatments,
  QuestionOne,
  QuestionSeven,
  QuestionSix,
  QuestionThree,
  QuestionTwo,
  QuoteDecline,
  QuoteReview,
  Review,
} from "@/config/app-constant";
import { MEDICAL_FLOW_V2_ENABLED } from "@/constants";

export type PageName =
  | typeof LandingPage
  | typeof QuestionOne
  | typeof QuestionTwo
  | typeof QuestionThree
  | typeof QuestionFour
  | typeof QuestionFive
  | typeof QuestionSix
  | typeof QuestionSeven
  | typeof QuestionEight
  | typeof MedicalLandingPage
  | typeof MedicalLanding
  | typeof QuestionMedicalConfirmation
  | typeof QuestionMedicalConditions
  | typeof QuestionMedicalTreatments
  | typeof MedicalDashboard
  | typeof MedicalScreen
  | typeof MedicalNothingToDeclare
  | typeof ContactDetails
  | typeof QuoteDecline
  | typeof Review
  | typeof QuoteReview;

interface Pages {
  name: PageName;
  prev: PageName | null;
  next: PageName | null;
  progress: number;
}

type QuoteJourneyPages = {
  name: PageName;
  isActive: boolean;
};

const newMedicalFlowEnabled = MEDICAL_FLOW_V2_ENABLED === "true";

const quoteJourneyPages: QuoteJourneyPages[] = [
  {
    name: LandingPage,
    isActive: false,
  },
  {
    name: QuestionOne,
    isActive: true,
  },
  {
    name: QuestionTwo,
    isActive: false,
  },
  {
    name: QuestionThree,
    isActive: true,
  },
  {
    name: QuestionFour,
    isActive: true,
  },
  {
    name: QuestionFive,
    isActive: true,
  },
  {
    name: QuestionSix,
    isActive: true,
  },
  {
    name: QuestionSeven,
    isActive: true,
  },
  {
    name: QuestionEight,
    isActive: true,
  },
  {
    name: MedicalLandingPage,
    isActive: true,
  },
  {
    name: MedicalLanding,
    isActive: false,
  },
  {
    name: QuestionMedicalConfirmation,
    isActive: true,
  },
  {
    name: QuestionMedicalConditions,
    isActive: true,
  },
  {
    name: QuestionMedicalTreatments,
    isActive: true,
  },
  {
    name: MedicalDashboard,
    isActive: true,
  },
  {
    name: MedicalNothingToDeclare,
    isActive: true,
  },
  {
    name: ContactDetails,
    isActive: true,
  },
  {
    name: QuoteDecline,
    isActive: true,
  },
  {
    name: Review,
    isActive: false,
  },
];

export const getProgressBarPercentage = () => {
  if (newMedicalFlowEnabled) {
    const dynamicPercentage: Record<string, number> = {};
    dynamicPercentage[QuestionOne] = 7.5;
    dynamicPercentage[QuestionThree] = 15;
    dynamicPercentage[QuestionFour] = 22.5;
    dynamicPercentage[QuestionFive] = 30;
    dynamicPercentage[QuestionSix] = 37.5;
    dynamicPercentage[QuestionSeven] = 45;
    dynamicPercentage[QuestionEight] = 52.5;
    dynamicPercentage[QuestionMedicalConfirmation] = 60;
    dynamicPercentage[QuestionMedicalTreatments] = 67.5;
    dynamicPercentage[MedicalDashboard] = 75;
    dynamicPercentage[MedicalNothingToDeclare] = 83;
    dynamicPercentage[QuoteDecline] = 100;
    dynamicPercentage[ContactDetails] = 90;

    return dynamicPercentage;
  }
  const countActivePages = quoteJourneyPages.filter(
    (page) => page.isActive
  ).length;
  const percentageMaxValue = 100;
  const cal = (number: number) =>
    (percentageMaxValue / countActivePages) * number;
  let index = 0;
  const percentage = parseFloat((Math.round(cal(1) * 2) / 2).toFixed(1)); // per page percentage

  const dynamicPercentage = quoteJourneyPages.reduce(
    (acc, page: QuoteJourneyPages) => {
      if (page.isActive) {
        if (page.name !== LandingPage) {
          index += 1;
        }

        if (page.name === LandingPage) {
          acc[page.name] = -1;
        } else if (page.name === QuoteDecline) {
          acc[page.name] = 100;
        } else {
          acc[page.name] = percentage * index;
        }
      } else {
        acc[page.name] = -1;
      }
      return acc;
    },
    {} as Record<string, number>
  );

  return dynamicPercentage;
};

const auto = true;
const pagesPercentage = getProgressBarPercentage();

const pages: Pages[] = [
  {
    name: LandingPage,
    prev: null,
    next: QuestionOne,
    progress: auto ? pagesPercentage[LandingPage] : -1,
  },
  {
    name: QuestionOne,
    prev: null,
    next: QuestionTwo,
    progress: auto ? pagesPercentage[QuestionOne] : 6.5,
  },
  {
    name: QuestionTwo,
    prev: null,
    next: QuestionThree,
    progress: auto ? pagesPercentage[QuestionTwo] : -1,
  },
  {
    name: QuestionThree,
    prev: QuestionTwo,
    next: QuestionFour,
    progress: auto ? pagesPercentage[QuestionThree] : 13,
  },
  {
    name: QuestionFour,
    prev: QuestionThree,
    next: QuestionFive,
    progress: auto ? pagesPercentage[QuestionFour] : 19.5,
  },
  {
    name: QuestionFive,
    prev: QuestionFour,
    next: QuestionSix,
    progress: auto ? pagesPercentage[QuestionFive] : 26,
  },
  {
    name: QuestionSix,
    prev: QuestionFive,
    next: QuestionSeven,
    progress: auto ? pagesPercentage[QuestionSix] : 32.5,
  },
  {
    name: QuestionSeven,
    prev: QuestionSix,
    next: QuestionEight,
    progress: auto ? pagesPercentage[QuestionSeven] : 39,
  },
  {
    name: QuestionEight,
    prev: QuestionSeven,
    next: newMedicalFlowEnabled
      ? QuestionMedicalConfirmation
      : MedicalLandingPage,
    progress: auto ? pagesPercentage[QuestionEight] : 45.5,
  },
  {
    name: MedicalLandingPage,
    prev: QuestionSeven,
    next: QuestionMedicalConfirmation,
    progress: auto ? pagesPercentage[MedicalLandingPage] : 52,
  },
  {
    name: MedicalLanding,
    prev: QuestionEight,
    next: QuestionMedicalConfirmation,
    progress: auto ? pagesPercentage[MedicalLanding] : 52,
  },
  {
    name: QuestionMedicalConfirmation,
    prev: newMedicalFlowEnabled ? QuestionEight : MedicalLandingPage,
    next: newMedicalFlowEnabled
      ? QuestionMedicalTreatments
      : QuestionMedicalConditions,
    progress: auto ? pagesPercentage[QuestionMedicalConfirmation] : 58.5,
  },
  {
    name: QuestionMedicalConditions,
    prev: QuestionMedicalConfirmation,
    next: QuestionMedicalTreatments,
    progress: auto ? pagesPercentage[QuestionMedicalConditions] : 65,
  },
  {
    name: QuestionMedicalTreatments,
    prev: newMedicalFlowEnabled
      ? QuestionMedicalConfirmation
      : QuestionMedicalConditions,
    next: MedicalDashboard,
    progress: auto ? pagesPercentage[QuestionMedicalTreatments] : 71.5,
  },
  {
    name: MedicalDashboard,
    prev: QuestionMedicalTreatments,
    next: ContactDetails,
    progress: auto ? pagesPercentage[MedicalDashboard] : 78,
  },
  {
    name: MedicalScreen,
    prev: null,
    next: MedicalDashboard,
    progress: auto ? pagesPercentage[MedicalNothingToDeclare] : 84,
  },
  {
    name: MedicalNothingToDeclare,
    prev: null,
    next: ContactDetails,
    progress: auto ? pagesPercentage[MedicalNothingToDeclare] : 83,
  },
  {
    name: ContactDetails,
    prev: MedicalDashboard,
    next: null,
    progress: auto ? pagesPercentage[ContactDetails] : 91,
  },
  {
    name: QuoteDecline,
    prev: null,
    next: null,
    progress: auto ? pagesPercentage[QuoteDecline] : 100,
  },
  {
    name: Review,
    prev: null,
    next: QuoteReview,
    progress: auto ? pagesPercentage[Review] : -1,
  },
];

export default pages;

// Helper function to get the previous page for a given page
export const getRouterPathBackButton = (currentPage: PageName) => {
  const currentPageInfo = pages.find((page) => page.name === currentPage);
  return currentPageInfo?.prev ? `/${currentPageInfo.prev}` : `/`;
};

// Helper function to get the next page for a given page
export const getRouterPathMainButton = (currentPage: PageName) => {
  const currentPageInfo = pages.find((page) => page.name === currentPage);
  return currentPageInfo?.next ? `/${currentPageInfo.next}` : `/`;
};
