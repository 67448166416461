import { QUOTE_DASHBOARD_URL } from "@/constants";
import axios from "axios";
import { get } from "http";

const quoteDashBoard = async () => {
  let pageJson;
  await axios
    .get(`${QUOTE_DASHBOARD_URL}`)
    .then(async (res) => {
      pageJson = res.data;
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((e) => {
      pageJson = {};
    });
  return pageJson;
};

export default quoteDashBoard;
